import Message from '~/components/Message';
import { ReactNode, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import * as Sentry from '@sentry/react';
import LayoutPadding from '~/components/layout/LayoutPadding';

export default function RouteErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      Sentry.captureException(error.data);
    } else if (error instanceof Error) {
      if (error.message.includes('<!DOCTYPE html>')) {
        Sentry.captureException('Unexpected server side error has occurred.');
      } else {
        Sentry.captureException(error);
      }
    } else {
      Sentry.captureException('Unknown client side error has occurred.');
    }
  }, [error]);

  return (
    <LayoutPadding>
      <ErrorMessage />
    </LayoutPadding>
  );
}

export function StandardErrorBoundary({ children }: { children: ReactNode }) {
  return <ErrorBoundary fallback={<ErrorMessage />}>{children}</ErrorBoundary>;
}

function ErrorMessage() {
  return <Message type="error">An unexpected error has occurred.</Message>;
}
